import {Link} from 'react-router-dom'
import styles from './BottomMenu.module.css'

export default function BottomMenu() {
  return (
    <nav className={styles.bottom_menu_navigation}>
      <ul className={`list_ul ${styles.list}`}>
        <div className={styles.row_1}>
          <li>
            <Link className="link" to="/private/">
              <i className="fas fa-home"></i>
            </Link>
          </li>
        </div>
        {/*  */}
        <div className={styles.row_2}>
          <li>
            <Link className="link" to="/private/resgate-de-pontos">
              <i className="fas fa-exchange-alt"></i>
            </Link>
          </li>
        </div>
        {/*  */}
        <div className={styles.row_2}>
          <li>
            <Link className="link" to="/private/solicitacao-de-coleta">
              <i className="fas fa-truck"></i>
            </Link>
          </li>
        </div>
        {/*  */}
        <div className={styles.row_2}>
          <li>
            <Link className="link" to="/private/profile">
              <i className="fas fa-user"></i>
            </Link>
          </li>
        </div>
      </ul>
    </nav>
  )
}
