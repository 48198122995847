import ListagemStyle from './ListagemDeColeta.module.css'
import Container from '../../../components/Components-ui/Container'
import Button from '../../../components/Components-ui/Button/Button'
import InfoUser from '../../../components/InfoUser'

function ListagemDeColeta() {
  return (
    <Container>
      <section className={ListagemStyle.content}>
        <InfoUser />
        <section className={ListagemStyle.table_coleta}>
          <table className="table">
            <thead>
              <tr>
                <th colSpan="6" style={{textAlign: 'center'}}>
                  Listagem de Coletas
                </th>
              </tr>
              <tr>
                <th>Data</th>
                <th>N° Coleta</th>
                <th>Material</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Valor 1</td>
                <td>Valor 1</td>
                <td>Valor 1</td>
              </tr>
            </tbody>
          </table>
        </section>
        <section className={ListagemStyle.section_button}>
          <Button title="Solicite uma coleta" />
          <Button title="Troque seus $tng por prêmios" />
        </section>
      </section>
    </Container>
  )
}

export default ListagemDeColeta
