import './style/global.css'
import Routers from './Router'
import {BrowserRouter} from 'react-router-dom'
function App() {
  return (
    <BrowserRouter>
      <Routers />
    </BrowserRouter>
  )
}

export default App
