import styles from './Login.module.css'
import Button from '../../../components/Components-ui/Button/Button'
import Container from '../../../components/Components-ui/Container'
import {Link} from 'react-router-dom'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
export default function Login() {
  const SignupSchema = Yup.object().shape({
    password: Yup.string()
      .min(5, 'Digite um senha com no minimo 5 caracteres!')
      .max(10, 'senha muito longa, prefira senhas fáceis !')
      .required('*Campo obrigatório'),
    login: Yup.string().required('*Campo obrigatório'),
  })
  return (
    <Container>
      <section className={styles.content}>
        <img src="/assets/logo.png" alt="Logo" />
        <section className={styles.formLogin}>
          <Formik
            initialValues={{
              login: '',
              password: '',
            }}
            onSubmit={async (values) => console.log(values)}
            // validate={validate}
            validationSchema={SignupSchema}
          >
            {({touched, errors, isSubmitting}) => (
              <Form>
                <label htmlFor="login">Login</label>
                <Field
                  className="input"
                  id="login"
                  type="text"
                  name="login"
                  placeholder="ID | email | celular"
                />

                {errors.login && touched.login ? (
                  <div>
                    <span className={styles.show_alert_msg}>
                      <p>{errors.login}</p>
                    </span>
                  </div>
                ) : null}

                <label htmlFor="password">Senha</label>
                <Field
                  className="input"
                  id="password"
                  name="password"
                  type="password"
                  placeholder="*****"
                />
                {errors.password && touched.password ? (
                  <div>
                    <span className={styles.show_alert_msg}>
                      <p>{errors.password}</p>
                    </span>
                  </div>
                ) : null}
                <Button title="Login" disabled={isSubmitting} />
              </Form>
            )}
          </Formik>
        </section>
        <Link className={`link ${styles.link}`} to="/public">
          Seja um embaixador - Clique aqui
        </Link>
      </section>
    </Container>
  )
}
