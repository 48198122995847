import applicationStyle from './Application.module.css'
import BottomMenu from '../components/BottomMenu'
import Header from '../components/Header'
import Main from '../components/Main'

export function Logged() {
  return (
    <div className={applicationStyle.container}>
      <Header extra_menu={true} />
      <Main logged={true} />
      <BottomMenu />
    </div>
  )
}
export function NoLogged() {
  return (
    <div className={applicationStyle.container}>
      <Header />
      <Main logged={false} />
    </div>
  )
}
