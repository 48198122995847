import ProfileStyle from './Profile.module.css'
import Container from '../../../components/Components-ui/Container'
import Button from '../../../components/Components-ui/Button/Button'
export default function Profile() {
  return (
    <Container>
      <section className={ProfileStyle.content}>
        <div className={ProfileStyle.formUpdateUser}>
          <section className={ProfileStyle.sectionInput}>
            <label> Nome do Solicitante </label>
            <input type="text" className="input" />
            <label> Senha </label>
            <input type="password" className="input" />
            <label> Senha </label>
            <input type="password" className="input" />
          </section>
          <section className={ProfileStyle.sectionButtons}>
            <Button title="Salvar" />
            <Button title="Cancelar" />
          </section>
        </div>
      </section>
    </Container>
  )
}
