import styles from './ResgateDePontos.module.css'
import Container from '../../../components/Components-ui/Container'
import InfoUser from '../../../components/InfoUser'
import Card from '../../../components/Components-ui/Card'
import Button from '../../../components/Components-ui/Button/Button'
import 'semantic-ui-css/semantic.min.css'
export default function SolicitacaoDeColeta() {
  return (
    <Container>
      <section className={styles.content}>
        <InfoUser />
        <h3> Recompensas </h3>
        {/* <section className={styles.main_content}> */}
        <div className={styles.main}>
          <div className={styles.row}>
            <div className={styles.col_1}>
              <Card
                id="card1"
                title="Prêmio 1"
                description="Teste"
                img="https://images.tcdn.com.br/img/img_prod/497460/camiseta_o_grito_282_3_20190314163301.jpeg"
              />
            </div>
            <div className={styles.col_1}>
              <Card
                id="card2"
                title="Prêmio 2"
                description="Teste"
                img="https://d2ofpir5gh0cbr.cloudfront.net/files/2016-08/1472076351_caneca-dica1.jpg"
              />
            </div>
            <div className={styles.col_1}>
              <Card
                id="card3"
                title="Prêmio 3"
                description="Teste"
                img="https://images-americanas.b2w.io/produtos/01/00/img/1743736/0/1743736019_1GG.jpg"
              />
            </div>
            <div className={styles.col_1}>
              <Card
                id="card4"
                title="Prêmio 1"
                description="Teste"
                img="https://images.tcdn.com.br/img/img_prod/497460/camiseta_o_grito_282_3_20190314163301.jpeg"
              />
            </div>
            <div className={styles.col_1}>
              <Card
                id="card5"
                title="Prêmio 1"
                description="Teste"
                img="https://images.tcdn.com.br/img/img_prod/497460/camiseta_o_grito_282_3_20190314163301.jpeg"
              />
            </div>
            <div className={styles.col_1}>
              <Card
                id="card2"
                title="Prêmio 2"
                description="Teste"
                img="https://d2ofpir5gh0cbr.cloudfront.net/files/2016-08/1472076351_caneca-dica1.jpg"
              />
            </div>
            {/* <div className={styles.card}>
            <span className={styles.check}>
            <input type="checkbox" id="select_item" />
            <label htmlFor="select_item">Selecionar</label>
            </span>
            <div className={styles.card_product}>
              <img width="100px" src="" alt="Imagem Teste" />
            </div>
            <div className={styles.card_descripton}>
              <span>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
                  porta laoreet
                </p>
              </span>
            </div>
          </div> */}
          </div>
        </div>
        {/* </section> */}
        <section className={styles.section_button}>
          <Button title="Resgate" />
        </section>
      </section>
    </Container>
  )
}
