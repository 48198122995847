import InfoUser from '../../../components/InfoUser'
import Container from '../../../components/Components-ui/Container'
import styles from './SolicitacaoDeColeta.module.css'
import Button from '../../../components/Components-ui/Button/Button'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
export default function SolicitacaoDeColeta() {
  const validationScheema = Yup.object().shape({
    qtd: Yup.string()
      // .moreThan(0, 'Digite um valor maior que zero')
      // .matches(/^(?<=\s|^)\d+(?=\s|$)/, 'O campo deve ser do tipo número')
      // /^(?:\s|^)\d+(?=\s|$)/
      .matches(/^[0-9]+$/, 'Digite um valor numérico inteiro e maior que zero')
      // .positive('Digite uma quantidade que 1Kg.')
      .required('*Digite a quantidade de óleo'),
    observacoes: Yup.string().min(
      5,
      'Descreva sua observação, com mais palavras'
    ),
  })
  return (
    <Container>
      <section className={styles.content}>
        <InfoUser />
        <Formik
          initialValues={{
            qtd: '',
            observacoes: '',
          }}
          onSubmit={async (values) => console.log(values)}
          validationSchema={validationScheema}
        >
          {({touched, errors, isSubmitting}) => (
            <Form>
              <span>Solicitação de Coleta</span>
              <div className={styles.input_lts}>
                <label> Informe a quantidade estimada(Kg)</label>
                <Field className="input" type="text" name="qtd" />
              </div>
              <div>
                <span className={styles.show_alert_msg}>
                  <p>{errors.qtd && touched.qtd ? errors.qtd : null}</p>
                </span>
              </div>

              <div className={styles.info_endereco}>
                <p>Endereço da coleta</p>
                <p>Rua Gomes da Silva, 123</p>
                <p>Centro - SP - SP - CEP 04588-000</p>
              </div>
              <div className={styles.input_lts}>
                <label> Observações</label>
                <Field
                  className="input"
                  component="textarea"
                  name="observacoes"
                />
              </div>
              <div>
                <span className={styles.show_alert_msg}>
                  <p>
                    {errors.observacoes && touched.observacoes
                      ? errors.observacoes
                      : null}
                  </p>
                </span>
              </div>

              <section className={styles.section_button}>
                <Button title="Salvar" />
              </section>
            </Form>
          )}
        </Formik>
      </section>
    </Container>
  )
}
