import containerStyle from './Container.module.css'
export default function Container({children}) {
  const div = {
    padding: '20px 10px ',
    overflow: 'auto',
    height: '100%',
  }
  return (
    <div style={div} className={containerStyle.container}>
      {children}
    </div>
  )
}
