import {Route, Switch} from 'react-router-dom'
//
import Register from './Public/Register'
import Login from './Public/Login'
//
import ListagemDeColeta from './Private/ListagemDeColeta'
import ResgateDePontos from './Private/ResgateDePontos'
import SolicitacaoDeColeta from './Private/SolicitacaoDeColeta'
import Profile from './Private/Profile'
//
export default function Routers({router}) {
  const logged = [
    {
      path: '/private/',
      component: ListagemDeColeta,
    },
    {
      path: '/private/resgate-de-pontos',
      component: ResgateDePontos,
    },
    {
      path: '/private/solicitacao-de-coleta',
      component: SolicitacaoDeColeta,
    },
    {
      path: '/private/profile',
      component: Profile,
    },
  ]
  //
  const noLogged = [
    {
      path: '/public',
      component: Register,
    },
    {
      path: '/public/login',
      component: Login,
    },
  ]
  function isLogged() {
    if (router === 'logged') {
      return logged
    } else if (router === 'nologged') {
      return noLogged
    }
  }
  const userLogin = isLogged()
  return (
    <Switch>
      {userLogin.map(({path, component}, index) => (
        <Route key={index} path={path} component={component} exact />
      ))}
    </Switch>
  )
}
