import {Card, Image} from 'semantic-ui-react'

export default function CardExampleCard({img, title, description, id}) {
  const style = {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
  }
  return (
    <Card>
      <Image src={img} wrapped ui={false} />
      <Card.Content>
        <Card.Header>{title}</Card.Header>
        <Card.Description>{description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <span style={style}>
          <input type="checkbox" id={id} />
          <label htmlFor={id}>Selecionar</label>
        </span>
      </Card.Content>
    </Card>
  )
}
