import {useState} from 'react'
import {Link} from 'react-router-dom'
import styles from './Header.module.css'
export default function Header({extra_menu}) {
  const [showMenu, setShowMenu] = useState(false)
  function handleMenu() {
    if (showMenu) setShowMenu(false)
    if (!showMenu) setShowMenu(true)
  }
  return (
    <>
      <header className={styles.header}>
        <span className={styles.left_side}>
          <label htmlFor="menu" className={styles.label_menu}>
            <i className="fas fa-bars"></i>
          </label>
          <input
            id="menu"
            type="checkbox"
            style={{display: 'none'}}
            onClick={handleMenu}
          />
        </span>
        <span className={styles.right_side}>
          <img src="/assets/logo.png" alt="Logo" />
        </span>
      </header>
      {extra_menu ? (
        <div className={showMenu ? styles.menuHamburguer : styles.hide}>
          <ul className={`list_ul ${styles.options_menu}`}>
            <li>
              <Link
                className="link"
                to="#"
                onClick={() => setShowMenu(!showMenu)}
              >
                Institucional
              </Link>
            </li>
            <li>
              <Link
                className="link"
                to="#"
                onClick={() => setShowMenu(!showMenu)}
              >
                Junte Óleo
              </Link>
            </li>
            <li>
              <Link
                className="link"
                to="#"
                onClick={() => setShowMenu(!showMenu)}
              >
                Serviços
              </Link>
            </li>
            <li>
              <Link
                className="link"
                to="#"
                onClick={() => setShowMenu(!showMenu)}
              >
                Pontos de Coleta
              </Link>
            </li>

            <li className={styles.extra_menu}>
              <Link
                className="link"
                to="/private"
                onClick={() => setShowMenu(!showMenu)}
              >
                Listagem de Coleta
              </Link>
            </li>
            <li className={styles.extra_menu}>
              <Link
                className="link"
                to="/private/resgate-de-pontos"
                onClick={() => setShowMenu(!showMenu)}
              >
                Recompensas
              </Link>
            </li>
            <li className={styles.extra_menu}>
              <Link
                className="link"
                to="/private/solicitacao-de-coleta"
                onClick={() => setShowMenu(!showMenu)}
              >
                Solicitação de Coleta
              </Link>
            </li>
            <li className={styles.extra_menu}>
              <Link
                className="link"
                to="/private/profile"
                onClick={() => setShowMenu(!showMenu)}
              >
                Perfil
              </Link>
            </li>
            <li>
              <Link
                className="link"
                to="/public/login"
                target="_self"
                onClick={() => setShowMenu(!showMenu)}
              >
                Sair
              </Link>
            </li>
          </ul>
        </div>
      ) : (
        <div className={showMenu ? styles.menuHamburguer : styles.hide}>
          <ul className={styles.options_menu}>
            <li>
              <Link
                className="link"
                to="#"
                onClick={() => setShowMenu(!showMenu)}
              >
                Institucional
              </Link>
            </li>
            <li>
              <Link
                className="link"
                to="#"
                onClick={() => setShowMenu(!showMenu)}
              >
                Junte Óleo
              </Link>
            </li>
            <li>
              <Link
                className="link"
                to="#"
                onClick={() => setShowMenu(!showMenu)}
              >
                Serviços
              </Link>
            </li>
            <li>
              <Link
                className="link"
                to="#"
                onClick={() => setShowMenu(!showMenu)}
              >
                Pontos de Coleta
              </Link>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}
