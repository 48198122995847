import infoStyle from './InfoUser.module.css'
export default function InfoUser() {
  const user = 'Felipe'
  const tng = 20
  return (
    <section className={infoStyle.info_user}>
      <strong>Olá {user} </strong> <br />
      <span>Você já acumulou {tng} $tng</span>
    </section>
  )
}
