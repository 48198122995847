import { Switch, Route, Redirect } from 'react-router-dom'
import { Logged, NoLogged } from './Application/'

export default function Routers() {
  return (
    <Switch>
      <Redirect exact from="/" to="/public" />
      <Route path="/public" component={NoLogged} />
      <Route path="/private" component={Logged} />
      <Route component={() => <div>PAGE 404 - NOT FOUND</div>} />
    </Switch>
  )
}
