import InputMask from 'react-input-mask'
export default function InputMasked({mask, onChange, name, id}) {
  return (
    <InputMask
      id={id}
      className="input"
      name={name}
      mask={mask}
      maskChar="_"
      onChange={onChange}
    />
  )
}
