import styles from './Register.module.css'
import InputMasked from '../../../components/Components-ui/InputMask'
import Button from '../../../components/Components-ui/Button/Button'
import Container from '../../../components/Components-ui/Container'
import Loading from '../../../components/Components-ui/Loading'
import api from '../../../services/api'
import axios from 'axios'
import {Link} from 'react-router-dom'
import {useState} from 'react'
import {Formik, Form, Field} from 'formik'
import * as Yup from 'yup'
//
import {ToastContainer, toast} from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

export default function Cadastro() {
  //
  const [alertMsgCep, setAlertMsgCep] = useState(false)
  const [alertMsgCpf, setAlertMsgCpf] = useState(false)
  const [statusMsg, setStatusMsg] = useState('')
  const [showLoadingCpf, setShowLoadingCpf] = useState(false)
  const [showLoadingCep, setShowLoadingCep] = useState(false)
  //
  function replaceStr(str) {
    let replaceStr = str
    replaceStr = replaceStr.replace(/[^0-9]/g, '')
    return replaceStr
  }
  //

  function showAlert(notify, msg) {
    if (notify === 0) {
      toast.success(msg)
    } else {
      toast.error(msg)
    }
  }

  //validação do número
  function validateNumber(event, setFieldValue) {
    let numero = replaceStr(event)
    setFieldValue('celular', numero)
  }
  //Validação do cpf
  async function validateCpf(event, setFieldValue) {
    let cpf = replaceStr(event)
    if (cpf.length >= 11) {
      setShowLoadingCpf(true)
      const response = await api.post('/embaixador/checacpfcadastro', {
        cpf: cpf,
      })
      if (response.data.status === 1) {
        setStatusMsg(response.data.status_msg)
        setShowLoadingCpf(false)
        setAlertMsgCpf(true)
        setFieldValue('cpf', cpf)
      } else {
        setShowLoadingCpf(false)
        setAlertMsgCpf(false)
        setFieldValue('cpf', cpf)
      }
    } else {
      setAlertMsgCpf(false)
    }
  }
  // Validação de endereço
  async function getEndereco(event, setFieldValue) {
    let cep = replaceStr(event)
    if (cep.length >= 8) {
      setShowLoadingCep(true)
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`)
      if (response.data.erro) {
        setAlertMsgCep(true)
        setShowLoadingCep(false)
        setFieldValue('cep', cep)
      } else {
        setShowLoadingCep(false)
        let tipoLogradouro = response.data.logradouro.split(' ', 1)
        let logradouro = response.data.logradouro
          .replace(tipoLogradouro, '')
          .trim()
        const newData = {
          bairro: response.data.bairro,
          cidade: response.data.localidade,
          uf: response.data.uf,
          tipo_logradouro: tipoLogradouro[0],
          logradouro: logradouro,
        }
        setFieldValue('cep', cep)
        setFieldValue('bairro', newData.bairro)
        setFieldValue('cidade', newData.cidade)
        setFieldValue('uf', newData.uf)
        setFieldValue('id_tipo_logradouro', newData.tipo_logradouro)
        setFieldValue('logradouro', newData.logradouro)
      }
    } else {
      setAlertMsgCep(false)
      setFieldValue('bairro', '')
      setFieldValue('cidade', '')
      setFieldValue('uf', '')
      setFieldValue('id_tipo_logradouro', '')
      setFieldValue('logradouro', '')
    }
  }
  //Validação de dados
  const dataValidation = Yup.object().shape({
    nome_embaixador: Yup.string()
      .min(10, 'Digite seu nome completo!')
      .max(50, 'Nome muito longo!')
      .required('*Campo nome é obrigatório'),
    sexo: Yup.string().required('*Campo sexo é obrigatório'),
    cpf: Yup.string()
      .min(1, 'Digite um cpf válido')
      .max(11, 'Digite um cpf válido')
      .required('*Campo cpf é obrigatório'),
    cep: Yup.string()
      .min(8, 'Digite um cep válido')
      .max(9, 'Digite um cep válido')
      .required('*Campo cep é  obrigatório'),
    id_tipo_logradouro: Yup.string()
      .min(1, 'Digite um tipo de logradouro válido')
      .max(50, 'Tipo de logradouro não esperado')
      .required('*Campo tipo logradouro é obrigatório'),
    logradouro: Yup.string()
      .min(1, 'Digite um logradouro válido')
      .max(50, 'Logradouro não esperado')
      .required('*Campo logradouro é obrigatório'),
    numero: Yup.string().required('*Campo obrigatório'),
    complemento: Yup.string()
      .min(5, 'Digite um complemento melhor')
      .max(30, 'Complemento não esperado'),
    cidade: Yup.string()
      .min(3, 'Digite o nome de uma cidade real')
      .max(30, 'Cidade não esperada')
      .required('*Campo cidade é obrigatório'),
    bairro: Yup.string()
      .min(3, 'Digite um bairro válido')
      .max(50, 'Bairro não esperado')
      .required('*Campo bairro é obrigatório'),
    uf: Yup.string()
      .min(2, 'Digite um UF válido')
      .max(5, 'Digite um UF válido')
      .required('*Campo uf é obrigatório'),
    celular: Yup.string()
      .min(11, 'Digite um número telefônico válido')
      .required('*Campo celular é obrigatório'),
    email: Yup.string()
      .email('Email Inválido')
      .required('*Campo email é obrigatório'),
    senha: Yup.string()
      .min(5, 'Digite uma senha com no minímo 5 caracteres')
      .max(20, 'Opte por usar uma senha fácil')
      .required('*Campo senha é obrigatório'),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref('senha'), null], 'As senhas não conferem')
      .required('*Campo confirmar senha é obrigatório'),
  })
  //Cadastra embaixador
  async function registerEmbaixador(data, resetForm) {
    const response = await api.post('/embaixador/cadastro', data)
    if (response.data.status === 0) {
      showAlert(0, response.data.status_msg)
      resetForm({})
    }
    if (response.data.status !== 0) {
      showAlert(1, response.data.status_msg)
    }
  }
  return (
    <Container>
      <ToastContainer />
      <section className={styles.content}>
        <p className={styles.apresentation}>
          "Seja nosso embaixador Triângulo e ganhe prêmios contribuindo com o
          meio ambiente"
        </p>
        <Formik
          initialValues={{
            nome_embaixador: '',
            sexo: '',
            cpf: '',
            cep: '',
            id_tipo_logradouro: '',
            logradouro: '',
            numero: '',
            complemento: '',
            cidade: '',
            bairro: '',
            uf: '',
            celular: '',
            email: '',
            senha: '',
            passwordConfirm: '',
          }}
          validationSchema={dataValidation}
          onSubmit={(values, {resetForm}) =>
            registerEmbaixador(values, resetForm)
          }
        >
          {({touched, errors, setFieldValue}) => (
            <Form>
              <section className={styles.form_register}>
                <div className={styles.row}>
                  <div className={styles.col_6}>
                    <label htmlFor="nomeUser"> Seu nome </label>
                    <Field
                      className="input"
                      id="nomeUser"
                      type="text"
                      placeholder="Digite seu nome completo"
                      name="nome_embaixador"
                    />
                  </div>
                  <div className={styles.col_1_column}>
                    <label htmlFor="sexo">Sexo</label>
                    <Field
                      id="sexo"
                      as="select"
                      name="sexo"
                      className={`select ${styles.select}`}
                    >
                      <option value="">Selecione</option>
                      <option value="1">Masc</option>
                      <option value="2">Fem</option>
                    </Field>
                  </div>
                </div>

                {errors.sexo && touched.sexo ? (
                  <span className={styles.error}>
                    <p>{errors.sexo}</p>
                  </span>
                ) : null}

                {errors.nome_embaixador && touched.nome_embaixador ? (
                  <span className={styles.error}>
                    <p>{errors.nome_embaixador}</p>
                  </span>
                ) : null}
                {/*  */}
                <label htmlFor="cpf"> CPF </label>
                <Field name="cpf">
                  {({field}) => (
                    <InputMasked
                      id="cpf"
                      name={field.name}
                      mask="999.999.999-99"
                      onChange={(event) =>
                        validateCpf(event.target.value, setFieldValue)
                      }
                    />
                  )}
                </Field>

                {errors.cpf && touched.cpf ? (
                  <span className={styles.error}>
                    <p>{errors.cpf}</p>
                  </span>
                ) : null}
                <span
                  className={
                    alertMsgCpf ? styles.show_alert_cpf : styles.hidden
                  }
                >
                  <strong>{statusMsg}</strong>
                </span>
                <div
                  className={
                    showLoadingCpf ? styles.modal_loading : styles.hidden
                  }
                >
                  <div className={styles.show_loading}>
                    <strong> Aguarde... </strong>
                    <Loading display={showLoadingCpf ? 'block' : 'none'} />
                  </div>
                </div>
                {/*  */}
                <label htmlFor="cep"> CEP </label>
                <Field name="cep">
                  {({field}) => (
                    <InputMasked
                      id="cep"
                      name={field.name}
                      mask="99999-999"
                      onChange={(event) =>
                        getEndereco(event.target.value, setFieldValue)
                      }
                    />
                  )}
                </Field>
                {errors.cep && touched.cep ? (
                  <span className={styles.error}>
                    <p>{errors.cep}</p>
                  </span>
                ) : null}

                <span
                  className={
                    alertMsgCep ? styles.show_alert_cpf : styles.hidden
                  }
                >
                  <strong>CEP não encontrado.</strong>
                </span>
                <div
                  className={
                    showLoadingCep ? styles.modal_loading : styles.hidden
                  }
                >
                  <div className={styles.show_loading}>
                    <strong> Aguarde... </strong>
                    <Loading display={showLoadingCep ? 'block' : 'none'} />
                  </div>
                </div>
                <div className={styles.row}>
                  <div className={styles.col_1}>
                    <section className={styles.half_section}>
                      <label htmlFor="tipoLogradouro"> Tipo Logr. </label>
                      <Field
                        className="input"
                        id="tipoLogradouro"
                        type="text"
                        style={{width: 100}}
                        placeholder="Ex: Rua / Praça"
                        name="id_tipo_logradouro"
                      />
                    </section>
                  </div>

                  <div className={styles.col_6}>
                    <section className={styles.half_section}>
                      <label htmlFor="logradouro"> Logradouro </label>
                      <Field
                        id="logradouro"
                        type="text"
                        className="input"
                        placeholder="Ex: Doutor Afonso Vergueiro"
                        name="logradouro"
                      />
                    </section>
                  </div>
                </div>
                {errors.id_tipo_logradouro && touched.id_tipo_logradouro ? (
                  <span className={styles.error}>
                    <p>{errors.id_tipo_logradouro}</p>
                  </span>
                ) : null}
                {errors.logradouro && touched.logradouro ? (
                  <span className={styles.error}>
                    <p>{errors.logradouro}</p>
                  </span>
                ) : null}
                <div className={styles.row}>
                  <div className={styles.col_1}>
                    <section className={styles.half_section}>
                      <label htmlFor="numero"> Número </label>
                      <Field
                        className="input"
                        id="numero"
                        type="text"
                        style={{width: 100}}
                        placeholder="Ex: 100"
                        name="numero"
                      />
                    </section>
                  </div>
                  <div className={styles.col_6}>
                    <section className={styles.half_section}>
                      <label htmlFor="complemento"> Complemento </label>
                      <Field
                        className="input"
                        id="complemento"
                        type="text"
                        name="complemento"
                      />
                    </section>
                  </div>
                </div>
                {errors.numero && touched.numero ? (
                  <span className={styles.error}>
                    <p>{errors.numero}</p>
                  </span>
                ) : null}
                {errors.complemento && touched.complemento ? (
                  <span className={styles.error}>
                    <p>{errors.complemento}</p>
                  </span>
                ) : null}
                <label htmlFor="bairro"> Bairro </label>
                <Field
                  className="input"
                  id="bairro"
                  type="text"
                  name="bairro"
                />
                {errors.bairro && touched.bairro ? (
                  <span className={styles.error}>
                    <p>{errors.bairro}</p>
                  </span>
                ) : null}
                <div className={styles.row}>
                  <div className={styles.col_6}>
                    <section className={styles.half_section}>
                      <label htmlFor="cidade"> Cidade </label>
                      <Field
                        className="input"
                        name="cidade"
                        id="cidade"
                        type="text"
                      />
                    </section>
                  </div>
                  <div className={styles.col_1}>
                    <section className={styles.half_section}>
                      <label htmlFor="uf"> UF </label>
                      <Field
                        className="input"
                        id="uf"
                        type="text"
                        style={{width: 100}}
                        name="uf"
                      />
                    </section>
                  </div>
                </div>
                {errors.cidade && touched.cidade ? (
                  <span className={styles.error}>
                    <p>{errors.cidade}</p>
                  </span>
                ) : null}
                {errors.uf && touched.uf ? (
                  <span className={styles.error}>
                    <p>{errors.uf}</p>
                  </span>
                ) : null}

                <label htmlFor="celular"> Celular </label>
                <Field name="celular">
                  {({field}) => (
                    <InputMasked
                      id="celular"
                      name={field.name}
                      mask="(99) 99999-9999"
                      onChange={(event) =>
                        validateNumber(event.target.value, setFieldValue)
                      }
                    />
                  )}
                </Field>

                {errors.celular && touched.celular ? (
                  <span className={styles.error}>
                    <p>{errors.celular}</p>
                  </span>
                ) : null}
                <label htmlFor="email"> Email </label>
                <Field
                  className="input"
                  id="email"
                  type="email"
                  placeholder="ex@exemplo.com"
                  name="email"
                />
                {errors.email && touched.email ? (
                  <span className={styles.error}>
                    <p>{errors.email}</p>
                  </span>
                ) : null}

                <label htmlFor="senha"> Senha </label>
                <Field
                  className="input"
                  id="senha"
                  type="password"
                  placeholder="*****"
                  name="senha"
                />

                {errors.senha && touched.senha ? (
                  <span className={styles.error}>
                    <p>{errors.senha}</p>
                  </span>
                ) : null}

                <label htmlFor="confirmaSenha"> Confirmar Senha </label>
                <Field
                  className="input"
                  id="confirmaSenha"
                  type="password"
                  placeholder="*****"
                  name="passwordConfirm"
                />

                {errors.passwordConfirm && touched.passwordConfirm ? (
                  <span className={styles.error}>
                    <p>{errors.passwordConfirm}</p>
                  </span>
                ) : null}
                <Button title="Cadastrar" />
              </section>
            </Form>
          )}
        </Formik>

        <Link className={`link ${styles.link}`} to="/public/login">
          Já tenho uma conta !!!
        </Link>
      </section>
    </Container>
  )
}
