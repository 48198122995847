import style from './Button.module.css'

export default function Button(props) {
  return (
    <>
      <button
        className={style.btn}
        style={{display: props.display, backgroundColor: props.color}}
        onClick={props.onClick}
        type="submit"
      >
        {props.title}
      </button>
    </>
  )
}
