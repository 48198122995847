import MainStyle from './Main.module.css'
import Routers from '../../pages/Router'

export default function Main({ logged }) {
  function Logged() {
    return (
      <main className={MainStyle.main_logged}>
        <Routers router="logged" />
      </main>
    )
  }
  function NoLogged() {
    return (
      <main className={MainStyle.main_nologged}>
        <Routers router="nologged" />
      </main>
    )
  }

  return logged ? <Logged /> : <NoLogged />
}
